<template>
  <div class="head_right d-flex align-items-center justify-content-end w-100">

    <template v-if="position === 'bottom'">

      <!--            <b-dropdown ref="dropdownActionMenu"-->
      <!--                        right class="mr-2 d-inline-block table_action_dropdown icon_dropdown dropdown-menu-right default_style_dropdown"-->
      <!--                        :no-caret="true">-->
      <!--                <div class=" rounded_shape dropdown_header no_arrow text-right" slot="button-content">-->
      <!--                     <span class="text">-->
      <!--                         <i class="d-block fas fa-ellipsis-h"></i>-->
      <!--                     </span>-->
      <!--                </div>-->

      <!--                <ul class="clear inner ">-->
      <!--                    <li class="list_item_li"  @click.prevent="showBulkImportModal()">-->
      <!--                        <i style="min-width: 20px;" class="far  fa-file-import" aria-hidden="true"></i>Bulk Import-->
      <!--                    </li>-->
      <!--                    <li class="list_item_li " v-if="getLinks.items.length > 0" @click.prevent="$bvModal.show('export-links')">-->
      <!--                        <i style="padding-left: 3px;min-width: 20px;" class="far  fa-file-export" aria-hidden="true"></i>CSV Export-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--            </b-dropdown>-->

      <div class="import_round_btn rounded_shape dropdown_header no_arrow text-right" slot="button-content"
           @click.prevent="showBulkImportModal()">
        <i class="far  fa-file-import" aria-hidden="true"></i>
        <span class="text">
                    Bulk Import
                </span>
      </div>

      <!--            <div class="import_round_btn rounded_shape dropdown_header no_arrow text-right" slot="button-content"-->
      <!--                 @click.prevent="showCSVImportModal()">-->
      <!--                <i class="far  fa-file-import" aria-hidden="true"></i>-->
      <!--                <span class="text">-->
      <!--                    CSV Import-->
      <!--                </span>-->
      <!--            </div>-->

      <div class="import_round_btn rounded_shape dropdown_header no_arrow text-right" slot="button-content"
           v-if="getLinks.items.length > 0" @click.prevent="showExportCsvModal()">
        <i class="far  fa-file-export" aria-hidden="true"></i>
        <span class="text">
                    CSV Export
                </span>
      </div>

      <!--            <div class="mr-2 search_input_block no_animation " :class="{active_input : getLinks.search}">-->
      <!--                <input v-model="getLinks.search" placeholder="Search link" @keyup.enter="$store.dispatch('fetchLinks')"-->
      <!--                       required type="text">-->
      <!--                <i class="fal fa-search" @click.prevent="$store.dispatch('fetchLinks')"></i>-->
      <!--            </div>-->

      <!--<button @click.prevent="showBulkImportModal()"-->
      <!--class="mr-2 btn-&#45;&#45;cta btn-border btn-round light-blue btn-bold">-->
      <!--&lt;!&ndash;<i class="fa fa-upload" aria-hidden="true"></i>&ndash;&gt;-->
      <!--<span>Bulk import</span>-->
      <!--</button>-->

      <!--<button v-if="getProfile.policy.can_add_links"-->
      <!--@click.prevent="createNewLink()"-->
      <!--:class="{'btn&#45;&#45;effect-pulse': getProfile.onboarding === false }"-->
      <!--class="btn-&#45;&#45;cta btn-blue with-shadow btn-round   btn-bold">-->
      <!--<span>New Link</span>-->
      <!--<span class="icon_right">-->
      <!--<i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>-->
      <!--</span>-->
      <!--</button>-->

      <div class="toggle_btn_link with_border ">
        <div class="toggle_btn_inner">
          <a @click.prevent="showLinksArchive(false)"
             class="btn_link" :class="{'active_tab':!getLinks.archive}">
            Active Links
          </a>
          <a @click.prevent="showLinksArchive(true)"
             class="btn_link" :class="{'active_tab':getLinks.archive }">
            Archived Links
          </a>

        </div>
      </div>

    </template>
    <template v-if="position === 'bottom-search'">

      <div class="col-md-6">
        <div class="mr-2 search_input_block no_animation " :class="{active_input : getLinks.search}">
          <input v-model="getLinks.search" placeholder="Search links by shorten id"
                 @keyup.enter="fetchLinksAndAnalytics()"
                 required type="text" style="width: 400px !important; max-width: 480px !important;">
          <i class="fal fa-search" @click.prevent="fetchLinksAndAnalytics()"></i>
        </div>
      </div>

      <div class="col-md-6">
        <div class="right">
          <b-dropdown right
                      class="ml-2 d-inline-block dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"
                      :no-caret="true"
                      ref="resultsDropdown">
            <div class="dropdown_header rounded_shape d-flex align-items-center  "
                 slot="button-content">
              <span class="text">{{ resultsText }}</span>
              <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                             </span>
            </div>
            <ul class="inner results_filter_dropdown">
              <li class="list_item_li"
                  @click="results(10)">
                10 Results
                <i class="far fa-check"
                   v-if="isDefaultRouteLimit"></i>
              </li>
              <li class="list_item_li"
                  @click="results(20)">
                20 Results
                <i class="far fa-check"
                   v-if="$route.query.limit && $route.query.limit === 20"></i>
              </li>
              <li class="list_item_li"
                  @click="results(50)">
                50 Results
                <i class="far fa-check"
                   v-if="$route.query.limit && $route.query.limit === 50"></i>
              </li>
              <li class="list_item_li"
                  @click="results(100)">
                100 Results
                <i class="far fa-check"
                   v-if="$route.query.limit && $route.query.limit === 100"></i>
              </li>
            </ul>
          </b-dropdown>
        </div>
      </div>

    </template>

    <template v-else-if="position === 'top'">
      <div class="datepicker_field rounded input_field datepicker_input datePicker_parent input_with_icon_right">
        <input
          v-model="getLinks.date.label"
          class="linkDatePicker"
          readonly
          placeholder="Search by date..."
          type="text"
        >
        <i class="input_icon fal fa-angle-down"></i>
      </div>

      <b-dropdown right class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown"
                  :no-caret="true">
        <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content">
          <span class="text">{{ getSelectedFilter('links', 'tags') }}</span>
          <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>
        </div>
        <ul class="inner ">
          <!--//search....-->

          <li class="list_item_li top-li-border" v-if="getTagList && getTagList.length > 0">
            <p class="checkbox_input_image ml-auto">
              <input id="tag_check_all" v-model="select_all_tags" @change="tagsSelectAll()"
                     type="checkbox">
              <label style="color: #1f216e;" for="tag_check_all" class="checkbox_right">All Tags</label>
            </p>

          </li>

          <template v-if="getTagList && getTagList.length > 0">
            <template v-for="tag in getTagList">

              <li class="list_item_li">
                <div class="checkbox_input_image">
                  <input type="checkbox" @change="fetchLinksAndAnalytics()()" :id="tag._id" :value="tag._id"
                         v-model="getLinks.tags"
                  >
                  <label :for="tag._id" class="checkbox_right">
                    <div class="user_block d-flex align-items-center">
                      <!--<div class="user_picture">-->
                      <!--<div-->
                      <!--class="img"-->
                      <!--style="background: url('/img/profile_default.svg');"-->
                      <!--&gt;</div>-->
                      <!--</div>-->
                      <div class="user_content">
                        <p class="name">{{ limitTextLength(tag.tag, 21) }}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </li>

            </template>
          </template>
          <li class="no_text_li" v-else>
            You do not have any tags.
          </li>

        </ul>
      </b-dropdown>

      <b-dropdown v-if="!getLinks.archiveStatus" right
                  class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown" :no-caret="true">
        <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content">
          <span @click="fetchCampaignOnDropdown" class="text">{{ getSelectedFilter('links', 'campaign') }}</span>
          <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>

        </div>
        <ul id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')"
            class="inner ">
          <!--//search....-->

          <li class="list_item_li top-li-border">
            <p class="checkbox_input_image ml-auto">
              <input id="select_all_campaigns" v-model="select_all_campaigns"
                     @change="campaginsSelectAll()" type="checkbox">
              <label style="color: #1f216e;" for="select_all_campaigns" class="checkbox_right">All
                Campaigns</label>
            </p>

          </li>

          <template v-if="getComponentCampaignList && getComponentCampaignList.length">
            <li v-for="cta in getComponentCampaignList" class="list_item_li">
              <div class="checkbox_input_image">
                <input type="checkbox" :id="cta._id"
                       :value="cta._id"
                       @change="fetchLinksAndAnalytics()()"
                       v-model="getLinks.campaign">
                <label :for="cta._id" class="checkbox_right">
                  <div class="user_block d-flex align-items-center">
                    <div class="user_picture">
                      <div class="img" :style="brandImageLink(cta.brand.avatar)"></div>
                    </div>
                    <div class="user_content">
                      <p class="name">{{ limitTextLength(cta.name, 21) }}</p>
                    </div>
                  </div>
                </label>
              </div>
            </li>
            <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
              <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
            </li>
          </template>
          <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li mt-1">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
          <li class="no_text_li" v-else-if="!getComponentCampaignList.length">
            You do not have any Campaign.
          </li>

        </ul>
      </b-dropdown>

      <b-dropdown v-if="!getLinks.archiveStatus" right
                  class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown" :no-caret="true">
        <div @click="fetchBrandOnDropdown" class="dropdown_header rounded_shape d-flex align-items-center  "
             slot="button-content">
          <span class="text">{{ getSelectedFilter('links', 'brand') }}</span>
          <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>

        </div>
        <ul id="brand-filter-dropdown-scroll"
            @scroll="lazyScrollBrand('brand-filter-dropdown-scroll','fetchBrandList',1)" class="inner ">
          <!--//search....-->

          <li class="list_item_li top-li-border">
            <p class="checkbox_input_image ml-auto">
              <input id="select_all_brands" v-model="select_all_brands"
                     @change="brandsSelectAll()" type="checkbox">
              <label style="color: #1f216e;" for="select_all_brands" class="checkbox_right">All
                Brands</label>
            </p>

          </li>

          <template v-if="getComponentBrandList && getComponentBrandList.length">
            <li v-for="brand in getComponentBrandList" class="list_item_li">
              <div class="checkbox_input_image">
                <input type="checkbox" :id="brand._id"
                       :value="brand._id"
                       @change="fetchLinksAndAnalytics()"
                       v-model="getLinks.brand">
                <label :for="brand._id" class="checkbox_right">
                  <div class="user_block d-flex align-items-center">
                    <div class="user_picture">
                      <div class="img" :style="brandImageLink(brand.avatar)"></div>
                    </div>
                    <div class="user_content">
                      <p class="name">{{ limitTextLength(brand.name, 21) }}</p>
                    </div>
                  </div>
                </label>
              </div>
            </li>
            <li v-if="getBrandLoaders.retrieve" class="list_item_li">
              <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
            </li>
          </template>
          <li v-else-if="getBrandLoaders.retrieve" class="list_item_li mt-1">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
          <li class="no_text_li" v-else-if="!getBrandList.length">
            You do not have any Brand.
          </li>

        </ul>
      </b-dropdown>

      <b-dropdown right class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown"
                  :no-caret="true">
        <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content">
          <span class="text">Hide/Show Columns</span>
          <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>

        </div>
        <ul class="inner ">
          <!--//search....-->

          <li class="list_item_li top-li-border">
            <p class="checkbox_input_image ml-auto">
              <input id="link_column_select_all" @change="linkColumnSelectAll()"
                     v-model="checkAllColumn" type="checkbox">
              <label style="color: #1f216e;" for="link_column_select_all" class="checkbox_right">All
                Columns</label>
            </p>

          </li>

          <li v-for="(columns,index) in tableColumns" class="list_item_li">
            <div class="checkbox_input_image">
              <input type="checkbox" @change="linksSelectedColumnsCount()" v-model="columns.status"
                     :id="'column'+ columns.id">
              <label :for="'column'+ columns.id" class="checkbox_right">
                <div class="user_block">
                  <div class="user_content">
                    <p class="name">{{ columns.name }}</p>
                  </div>
                </div>
              </label>
            </div>
          </li>

        </ul>
      </b-dropdown>

      <guided-tour v-if="getGuidedTour.step8"></guided-tour>

    </template>

  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown'
import {linkTypes} from '@/state/modules/mutation-types'

export default ({
  props: {
    tableColumns: {},
    position: {
      default: ''
    }
  },
  data () {
    return {
      checkAllColumn: false,
      select_all_tags: false,
      select_all_campaigns: false,
      select_all_brands: false,
      resultsText: 'Results Per Page'
    }
  },
  components: {
    Dropdown
  },
  created () {
    this.getLinks.search = ''
  },
  mounted () {
    let self = this
    setTimeout(function () {
      self.callLinkDatePicker()
    }, 1000)
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getGuidedTour',
      'getLinks',
      'getTagList',
      'getComponentCampaignList',
      'getComponentBrandList',
      'getBrandLoaders',
      'getCampaignLoaders'
    ]),
    isDefaultRouteLimit () {
      if (this.$route.query && this.$route.query.limit === 10) {
        return true
      } else {
        if (!this.$route.query.hasOwnProperty('limit')) return true
      }
      return false
    },
  },
  methods: {
    ...mapActions(['fetchLinks', 'fetchLinkAnalytics']),
    async showLinksArchive (status) {
      this.$store.commit(linkTypes.SET_LINKS_ARCHIVE_VALUE, status)
      await this.fetchLinks()
      this.fetchLinkAnalytics()
    },
    campaginsSelectAll () {
      let campaigns = (this.select_all_campaigns) ? this.getComponentCampaignList.map(item => item._id) : []
      this.$store.commit(linkTypes.SET_LINKS_CAMPAIGNS_LIST, campaigns)
      // this.$store.dispatch('fetchLinks', 1)
      this.fetchLinksAndAnalytics(1)
    },
    brandsSelectAll () {
      let brands = (this.select_all_brands) ? this.getComponentBrandList.map(item => item._id) : []
      this.$store.commit(linkTypes.SET_LINKS_BRANDS_LIST, brands)
      // this.$store.dispatch('fetchLinks', 1)
      this.fetchLinksAndAnalytics(1)
    },
    tagsSelectAll () {
      if (this.select_all_tags) {
        let tags = this.getTagList.map(item => item._id)
        this.$store.commit(linkTypes.SET_LINK_SELECTED_TAGS, tags)
      } else {
        this.$store.commit(linkTypes.SET_LINK_SELECTED_TAGS, [])
      }
      // this.$store.dispatch('fetchLinks', 1)
      this.fetchLinksAndAnalytics(1)
    },

    linkColumnSelectAll () {
      if (this.checkAllColumn) {
        this.tableColumns.forEach(item => item.status = true)
      } else {
        this.tableColumns.forEach(item => item.status = false)
      }
    },
    linksSelectedColumnsCount () {
      this.checkAllColumn = (this.tableColumns.filter(item => item.status === true).length === this.tableColumns.length)
    },
    callLinkDatePicker () {
      let me = this
      $(document).ready(function () {
        $('.linkDatePicker').daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $('.linkDatePicker').on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $('.linkDatePicker').on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })
        $('.linkDatePicker').on('apply.daterangepicker', function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: ' ' + picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(linkTypes.SET_LINKS_DATE_RANGE, range)
          me.paginateLinks(1)
        })
      })
    },
    async showExportCsvModal () {
      this.$parent.isShowLinksCsvImportModal = true
      setTimeout(() => {
        this.$bvModal.show('export-links')
      }, 200)
    },
    results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({query: {...this.$route.query, limit: value}})
    },
    async fetchLinksAndAnalytics (page = 1) {
      await this.fetchLinks(page)
      this.fetchLinkAnalytics()
    }
  },
  watch: {
    'getLinks.tags.length' (value) {
      this.select_all_tags = (this.getTagList.length === value)
    },
    'getLinks.campaign.length' (value) {
      this.select_all_campaigns = (this.getComponentCampaignList.length === value)
    },
    'getLinks.brand.length' (value) {
      this.select_all_brands = (this.getComponentBrandList.length === value)
    },
    'getLinks.search' (value) {
      if (value.length === 0) {
        this.fetchLinksAndAnalytics()
      } else if (value && value.length >= 3) {
        this.fetchLinksAndAnalytics()
      }
    }
  }
})
</script>

<style scoped lang="less">
.import_round_btn {
  background-color: #ffffff;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 30px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e6ecf5;
  margin-right: 10px;
  cursor: pointer;

  .text {
    margin-left: 5px !important;
  }
}

.results_dropdown .dropdown-menu {
  width: 400px !important;
  max-width: 400px !important;
}

.default_style_dropdown .dropdown-menu .inner {
  overflow-y: auto !important;
}

.toggle_btn_inner {
  width: 100% !important;
}
</style>
