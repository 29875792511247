<template>
  <tbody class="list-content-body">
  <tr v-for="(link,index) in getLinks.items"
      :class="{'selected_item_row': getLinksBulkSelection.items.indexOf(link._id) >=0,
        'overlay_item_archived': getLinks.archive}">

    <template v-if="link.cta">
      <td>
        <div class="checkbox_input_image">
          <input type="checkbox" v-model="getLinksBulkSelection.items" :value="link._id"
                 :id="'bulk_selection_' + link._id">
          <label
            :for="'bulk_selection_' + link._id" class="checkbox_right">
          </label>
        </div>
      </td>
      <td style="text-align: left;">

        <div class="replug_link_generated profile__box"
        >
          <div class="name">
            <template v-if="link.status">
              <a class="word_break_all" target="_blank"
                 :href="getDomainURl(link)">{{ getDomainURl(link) }}</a>
            </template>
            <template v-else>
              <a class="word_break_all"
                 v-if="getSiteDetails.isWhiteLabel && getSiteDetails.custom_domain_name"
                 target="_blank"
                 :href="getSiteDetails.custom_domain_name+link.shorten_url">
                {{ getSiteDetails.custom_domain_name }}{{ link.shorten_url }}
              </a>
              <!--              <a class="word_break_all"-->
              <!--                 v-else-if="link.cta && link.cta.custom_domain && link.cta.custom_domain.url"-->
              <!--                 href="javascript:;">{{ link.cta.custom_domain.url }}/{{ link.shorten_url }}</a>-->
              <a class="word_break_all" target="_blank"
                 :href="getDomainURl(link)">{{ getDomainURl(link) }}</a>
            </template>
            <i
              v-tooltip="'Copy Link'"
              v-clipboard:copy="getDomainURl(link)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              class="fal fa-clipboard copy_link"></i>
          </div>

          <div class="url created_date table__name">
            Created {{ link.created_at | relativeCaptilize }}
          </div>
        </div>

      </td>

      <td v-if="tableColumns[0].status">

        <div class="d-flex align-items-start profile__box"
        >
          <div class="avatar">
            <div v-if="!link.information || !link.information.favicon_url || link.information.favicon_url=='data:,'"
                 style="font-size: 17px;" class="fal fa-globe"></div>
            <div v-else-if="link.information && link.information.favicon_url"
                 class="brand_img favicon_img"
                 :style="brandImageLink(link.information.favicon_url)"></div>
          </div>
          <div class="content">
            <div v-if="link.information && link.information.meta && link.information.meta.title"
                 class="name max_width_220 word_break" v-tooltip="link.information.meta.title">
              {{ limitTextLength(link.information.meta.title, 30) }}
            </div>
            <div class="clearfix"></div>
            <div class="url" v-tooltip="link.url">
              {{ limitTextLength(link.url, 30) }}
            </div>
          </div>
        </div>
      </td>
      <template v-if="tableColumns[1].status">

        <td v-if="link.cta && link.cta.name">
          <div>
            <p class="word_break" style="max-width: 180px;">
              {{ link.cta.name }}</p>
          </div>

        </td>

        <td v-else-if="link.cta.type !== 'retargeting' && link.cta.message_headline">
          <div>
            {{ link.cta.message_headline }}
          </div>

        </td>

        <td v-else>
          <div>
            <p> Untitled</p>
          </div>

        </td>
      </template>
      <td v-if="tableColumns[2].status" class="brand_cta">
        <div v-if="!link.fetchStats">
          {{ commaSeparatedNumber(link.total_visitors) }}
        </div>
        <skeleton v-else></skeleton>

      </td>
      <td v-if="tableColumns[7].status" class="brand_cta">
        <div v-if="!link.fetchStats">
          {{ commaSeparatedNumber(link.unique_visitors) }}
        </div>
        <skeleton v-else></skeleton>

      </td>
      <td v-if="tableColumns[3].status" style="text-align: center;">
        <div v-if="!link.fetchStats">
          {{ commaSeparatedNumber(link.total_clicks_and_conversions) }}
        </div>
        <skeleton v-else></skeleton>

      </td>
      <td v-if="tableColumns[4].status" style="text-align: center;">
        <div v-if="!link.fetchStats">
          {{ link.unique_visitors_conversion_rate }}%
        </div>
        <skeleton v-else></skeleton>

      </td>
      <template v-if="tableColumns[5].status">
        <td v-if="link.average_time">
          <div>
            {{ link.average_time }}
          </div>

        </td>
        <td v-else class="light_grey">
          <div>
            N/A
          </div>

        </td>
      </template>
      <td style="text-align: center; width: 200px" v-if="tableColumns[6].status">
        <link-tags :index="index" :link="link"></link-tags>

      </td>
      <td class="text-center ">

        <div class="actions text-center" v-if="link.archive">
          <template v-if="link.policy.archive">

            <i v-tooltip="'Unarchive'" class="far fa-recycle "
               @click.prevent="archiveLinkModal(link._id,index,false)"
               aria-hidden="true" data-cy="link-unarchive"></i>
          </template>
          <template v-else>
            -
          </template>
        </div>
        <template v-else>
          <b-dropdown ref="dropdownActionMenu" right offset="10"
                      class="d-inline-block table_action_dropdown  dropdown-menu-right default_style_dropdown"
                      :no-caret="true">
            <!--d-flex align-items-center-->
            <div class=" dropdown_header no_arrow text-right" slot="button-content">
                         <span class="text">
                             <i class="fal fa-ellipsis-h fw-500"></i>
                         </span>
              <!--<span class="arrow_icon ml-auto">-->
              <!--<i class=" fal fa-angle-down ml-3"></i>-->
              <!--</span>-->
            </div>
            <ul class="clear inner ">
              <li class="list_item">
                <a v-tooltip="'View Stats'" class="text"
                   @click.prevent="linkDetailView(link.cta.brand, link.cta, link)" data-cy="link-stats"
                >
                  <i class="far fa-line-chart"></i> View Stats
                </a>
              </li>
              <li class="list_item">
                <a
                  v-clipboard:copy="getDomainURl(link)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  href="javascript:;" data-cy="link-copy">
                  <i class="fal  fa-clipboard" aria-hidden="true"></i>
                  Copy Link
                </a>
              </li>
              <li class="list_item">
                <template v-if="link.qr_code_url">
                  <a @click.prevent="renderQRCode(link.qr_code_url)"
                     href="javascript:;">
                    <i class="far fa-qrcode" aria-hidden="true"></i>

                    Download QR Code
                  </a>
                </template>
                <template v-else>
                  <a
                    @click.prevent="createQRCode({url: getDomainURl(link), slug: link.shorten_url})"
                    href="javascript:;" data-cy="link-qr">
                    <i class="far fa-qrcode" aria-hidden="true"></i>
                    Download QR Code
                  </a>
                </template>

              </li>
              <template>
                <li class="list_item" v-if="link.policy.edit">
                  <a @click.prevent="editLink(link)"
                     target="_blank" href="" data-cy="link-edit">
                    <i class="far  fa-pencil"
                       aria-hidden="true"></i> Edit
                  </a>
                </li>
                <!--                <li class="list_item" v-if="link.policy.edit">-->
                <!--                  <a @click.prevent="duplicateLink(link)"-->
                <!--                     target="_blank" href="">-->
                <!--                    <i class="fal  fa-files-o"-->
                <!--                       aria-hidden="true"></i> Duplicate-->
                <!--                  </a>-->
                <!--                </li>-->
                <li class="list_item" v-if="link.policy.archive">
                  <a @click.prevent="archiveLinkModal(link._id,index,true)"
                     target="_blank" href="" data-cy="link-archive">
                    <i class="far  fa-recycle"
                       aria-hidden="true"></i> Archive
                  </a>
                </li>
                <li class="list_item" v-if="link.policy.delete">
                  <a @click.prevent="deleteLink({linkId: link._id,index:index})"
                     target="_blank" href="" data-cy="link-remove">
                    <i class="far  fa-trash"
                       aria-hidden="true"></i> Remove
                  </a>
                </li>
              </template>

            </ul>

          </b-dropdown>

          <template v-if="link.policy.edit">
            <label style="margin-left: 15px;"
                   class="switch round_switch text-center">
              <input @click="changeLinkStatus({linkId: link._id, status: !link.is_link_disabled,index})"
                     type="checkbox"
                     v-model="!link.is_link_disabled">
              <div class="slider round">
                <i v-if="!link.is_link_disabled" v-tooltip.top-center="'Disable link'"
                   class="far fa-power-off" aria-hidden="true" data-cy="link-disable"></i>
                <i v-else v-tooltip.top-center="'Enable link'"
                   class="far fa-power-off" aria-hidden="true" data-cy="link-enable"></i>
              </div>
            </label>
          </template>

        </template>

      </td>

    </template>

  </tr>
  </tbody>
</template>
<style lang="less">
@-moz-document url-prefix() {
  .default_style_dropdown .dropdown-menu li.list_item a {
    font-size: 13px;
  }
}


.selected_item_row {
  background: #ffffea;
}

</style>
<script>
import { mapActions, mapGetters } from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import LinkTags from '../LinkTags'
import { linkTypes, userTypes } from '@/state/modules/mutation-types'
import { shortenDomainURL } from '@/config/api'

export default ({
  components: {
    Dropdown,
    LinkTags
  },
  props: {
    tableColumns: {}
  },
  computed: {
    ...mapGetters([
      'getLinks',
      'getLinksBulkSelection'
    ])
  },
  data () {
    return {
      shorten_domain_url: shortenDomainURL,
      shorten_domain_url_name: shortenDomainURL.replace('http://', '').replace('https://', '')

    }
  },
  methods: {
    ...mapActions([
      'createQRCode',
      'renderQRCode',
      'changeLinkStatus',
      'deleteLink',
      'archiveLink'
    ]),
    getDomainURl (link) {
      return link.domain ? link.domain.url+link.shorten_url : link.domain.url+link.shorten_url
    },
    setAddTagStatus (index, status) {
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
      this.$set(this.getLinks.items[index], 'tagValue', '')
      this.$set(this.getLinks.items[index], 'addTag', status)
    },
    editLink (value) {
      if (!value['tags'] || value['tags'] == null) {
        value['tags'] = []
      }
      this.$store.commit(linkTypes.SET_LINKS_NEW_CREATED, false)
      this.$store.commit(linkTypes.SET_LINKS_DEFAULT_VALUE)
      this.$store.commit(linkTypes.SET_LINKS_EDIT_VALUE, value)
      this.$router.push({ 'name': 'link', query: { 'shorten_url': value.shorten_url } })
    },
    duplicateLink (value) {
      let newLink = JSON.parse(JSON.stringify(value))
      newLink._id = null
      newLink.id = null
      newLink.shorten_url = null
      newLink.cta = { domainId: null }
      delete newLink.information
      delete newLink.policy
      this.$store.commit(linkTypes.SET_LINKS_EDIT_VALUE, newLink)
      this.$router.push({ 'name': 'link' })
    },
    archiveLinkModal (link, index, status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        link: link,
        status: status,
        index: index
      })
      if (this.getProfile.hide_archive_message) {
        this.archiveLink()
      } else {
        $('#archiveLink').modal('show')
      }
      this.$refs.dropdownActionMenu[index].hide(true)
    },
    testClick (index, val) {
      getLinks.items[index].focus = val
      // link.focus = false
    }

  }
})
</script>
