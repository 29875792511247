<template>
    <thead class="list-content-head">
    <tr>
        <th class="text-center">
            <div class="checkbox_input_image select_all_checkbox">
                <input type="checkbox" id="bulk_selection" v-model="getLinksBulkSelection.all"
                       @change="eventChangeForBulkSelection()">
                <label for="bulk_selection" class="checkbox_right">
                </label>
            </div>
        </th>
        <th style="width: 200px;">{{ getSiteDetails.agency_name }} Link</th>
        <th v-if="tableColumns[0].status" style=" width: 280px;" class=" table__name">
            Original Link
        </th>
        <th v-if="tableColumns[1].status" style="width:184px" class=" table__name">
            Campaign
        </th>

        <th v-if="tableColumns[2].status" class="brand_cta cursor_pointer sorted_row"
            >Clicks
<!--          @click.prevent="changeLinkSort('click',getLinks.sort)"-->
<!--            <i v-if="getLinks.sort==='click_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-if="getLinks.sort==='click_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>

        <th v-if="tableColumns[7].status" class="brand_cta cursor_pointer sorted_row"
            >Unique Clicks
<!--          @click.prevent="changeLinkSort('unique',getLinks.sort)"-->
<!--            <i v-if="getLinks.sort==='unique_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-if="getLinks.sort==='unique_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>

        <th class="brand_cta cursor_pointer sorted_row" v-if="tableColumns[3].status"
            style="width: 100px;text-align: center;"
            >Conv.
<!--          @click.prevent="changeLinkSort('conv',getLinks.sort)"-->
<!--            <i v-if="getLinks.sort==='conv_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-if="getLinks.sort==='conv_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>
        <th class="brand_cta cursor_pointer sorted_row" v-if="tableColumns[4].status"
            style="width: 130px;"
            >Conv. rate
<!--          @click.prevent="changeLinkSort('conv_rate',getLinks.sort)"-->
<!--            <i v-if="getLinks.sort==='conv_rate_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-if="getLinks.sort==='conv_rate_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>
        <th v-if="tableColumns[5].status" style="width: 120px;">Avg. Time

        </th>

        <th v-if="tableColumns[6].status"
            style="text-align: center;max-width: 270px;min-width: 100px;">Tags
        </th>

        <th style="width: 150px;" class="text-center">Actions</th>
    </tr>
    </thead>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  props: {
    tableColumns: {}
  },
  computed: {
    ...mapGetters([
      'getLinks',
      'getLinksBulkSelection'

    ])
  },
  methods: {
    eventChangeForBulkSelection () {
      let value = this.getLinksBulkSelection.all
      let items = []
      if (value) {
        items = this.getLinks.items.map(item => item._id)
      }
      this.$set(this.getLinksBulkSelection, 'items', items)
    },
    'getLinksBulkSelection.items.length' (value) {
    }
  }

})
</script>

<style scoped lang="less">

.select_all_checkbox input[type="checkbox"] + label.checkbox_right:after,
.select_all_checkbox input[type="checkbox"] + label.checkbox_right:before {
  transform: translateY(-18px) !important;
}
</style>
