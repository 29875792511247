<template>

  <b-modal modal-class="modal_basic modal-bulk-import" id="export-links" hide-footer hide-header v-on-clickaway="close">
    <div v-on-clickaway="close">
      <img @click="close()" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            <template>
              Export Links to CSV
            </template>
          </h3>
        </div>

        <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">Select Brands</div>
            <div class="name">
              <div class="validation_input multiple_select">
                <multiselect :class="{'input-field-error': validations.brands}"
                             v-model="getExportLinks.brands"
                             placeholder="Select Your Brands"
                             label="name" track-by="_id" :options="allBrands"
                             :multiple="true"
                             @select="selectCSVBrandForExport"
                             @remove="removeCampaignsFromExportList"></multiselect>
                <span class="input-error" v-if="validations.brands">
                                {{ messages.brands }}
                            </span>
              </div>
            </div>
          </div>
        </div>

        <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">Select Campaigns</div>
            <div class="name">
              <div class="validation_input multiple_select">
                <multiselect :class="{'input-field-error': validations.brands}"
                             v-model="getExportLinks.campaigns"
                             placeholder="Select Your Campaigns"
                             label="name" track-by="_id" :options="getExportLinks.campaigns_options"
                             :multiple="true"></multiselect>
                <span class="input-error" v-if="validations.campaigns">
                            {{ messages.campaigns }}
                        </span>
              </div>
            </div>
          </div>
        </div>
        <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">Select Columns</div>
            <b-dropdown :class="{'input-field-error': validations.columns}" right
                        class="mt-3 dropdown-menu-right default_style_dropdown checkbox_dropdown"
                        :no-caret="true">
              <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content">
                <span class="text">Select Fields</span>
                <span class="arrow_icon ml-auto">
                        <i class=" fal fa-angle-down ml-3"></i>
                     </span>

              </div>
              <ul class="inner ">
                <!--//search....-->

                <li class="list_item_li top-li-border">
                  <p class="checkbox_input_image ml-auto">
                    <input id="select_all_fields_to_export"
                           @change="selectAllCsv()"
                           v-model="select_all_csv_export"
                           type="checkbox">
                    <label style="color: #1f216e;" for="select_all_fields_to_export"
                           class="checkbox_right">All Columns</label>
                  </p>

                </li>
                <li v-for="(column,index) in csvColumns" class="list_item_li">
                  <div class="checkbox_input_image">
                    <input type="checkbox" :value="column" v-model="selectedCsvColumns"
                           :id="'column_csv'+ index"
                    >
                    <label :for="'column_csv'+ index" class="checkbox_right">
                      <div class="user_block">
                        <div class="user_content">
                          <p class="name">{{ column }}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </li>

              </ul>
            </b-dropdown>

            <br>
            <span class="input-error" v-if="validations.columns">
                        {{ messages.columns }}
                    </span>
          </div>
        </div>

        <div class=" btn_block text-left">

          <button @click.prevent="validateAndExportCSV()" :disabled="csvUploadLoader"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--left btn-bold">
            <span>
                            Export Links
                    </span>
            <clip-loader class="ml-1" v-if="csvUploadLoader" :size="'15px'" :color="color"></clip-loader>
          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="close()"><span>Close</span>
          </button>

        </div>

      </div>
    </div>
  </b-modal>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import Dropdown from '@/ui/Dropdown'
import { linkTypes } from '@/state/modules/mutation-types'
import { mixin as clickaway } from 'vue-clickaway'

export default ({
  mixins: [clickaway],
  components: {
    Dropdown,
    Multiselect
  },
  data () {
    return {
      selectedCsvBrands: [],
      selectedCsvCampaigns: [],
      select_all_csv_export: false,
      csvColumns: [
        'Shortened URL',
        'Original URL',
        'Campaign',
        'Clicks',
        'Conversions',
        'Conversion Rate',
        'Tags',
        'Unique Clicks',
        'Creation Date'
      ],
      selectedCsvColumns: [
        'Shortened URL',
        'Original URL',
        'Campaign',
        'Clicks',
        'Conversions',
        'Creation Date'
      ],
      csvUploadLoader: false,
      messages: {
        brands: 'Brands cannot be empty',
        campaigns: 'Campaigns cannot be empty',
        columns: 'Fields to be export cannot be empty'

      },
      validations: {
        brands: false,
        campaigns: false,
        columns: false

      },
      allCampaign: [],
      allBrands: []
    }
  },
  async created () {
      this.allCampaign = await this.fetchCampaignsList({ page: 1, isFetchAll: true })
      this.allBrands = await this.fetchBrandList({ page: 1, isFetchAll: true })
  },
  methods: {
    ...mapActions(['fetchCampaignsList']),
    selectAllCsv () {
      if (this.select_all_csv_export) {
        this.selectedCsvColumns = this.csvColumns
      } else {
        this.selectedCsvColumns = []
      }
    },
    selectCSVBrandForExport (value) {
      let campaignsList = this.allCampaign.filter(item => item.brand_id === value._id)
      if (campaignsList) {
        this.$store.commit(linkTypes.SET_EXPORT_LINKS_CAMPAIGNS, this.getExportLinks.campaigns.concat(campaignsList))
        this.$store.commit(linkTypes.SET_EXPORT_LINKS_CAMPAIGNS_OPTION, this.getExportLinks.campaigns_options.concat(campaignsList))
      }
    },

    removeCampaignsFromExportList (value) {
      let campaignsIds = this.allCampaign.map(item => {
        if (item.brand_id === value._id) {
          return item._id
        }
      })
      let campaignsSelection = this.getExportLinks.campaigns.filter(item => campaignsIds.indexOf(item._id) < 0)
      let campaignsOptions = this.getExportLinks.campaigns_options.filter(item => campaignsIds.indexOf(item._id) < 0)
      this.$store.commit(linkTypes.SET_EXPORT_LINKS_CAMPAIGNS, campaignsSelection)
      this.$store.commit(linkTypes.SET_EXPORT_LINKS_CAMPAIGNS_OPTION, campaignsOptions)
    },

    setSelectedCsvColumn (column, index) {
      (this.selectedCsvColumns.indexOf(column) < 0) ? this.selectedCsvColumns[index] = column : this.selectedCsvColumns[index] = null
    },
    async validateAndExportCSV () {
      this.csvUploadLoader = true
      let columns = (this.selectedCsvColumns.filter(item => item !== null))
      this.validations.brands = (this.getExportLinks.brands.length === 0)
      this.validations.campaigns = (this.getExportLinks.campaigns.length === 0)
      this.validations.columns = (columns.length === 0)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        let res = await this.$store.dispatch('exportCSVLinks', {
          selection: this.selectedCsvColumns,
          campaigns: this.getExportLinks.campaigns
        })
        if (res && res.data && res.data.status) {
          this.close()
        }
        this.csvUploadLoader = false
      }
    },
    close () {
      this.getExportLinks.campaigns = []
      this.getExportLinks.brands = []
      this.validations = {
        brands: false,
        campaigns: false,
        columns: false
      }
      this.$parent.closeExportCsvModal()
    }
  },
  computed: {
    ...mapGetters([
      'getExportLinks'
    ])
  },
  watch: {
    'getExportLinks.campaigns.length' (value) {
      if (value && value > 0) this.validations.campaigns = false
    },
    'selectedCsvColumns.length' (value) {
      if (value && value > 0) this.validations.columns = false
    }
  }
})
</script>
