<template>

  <b-modal modal-class="modal_basic modal-bulk-import" id="bulk-import" no-close-on-backdrop hide-footer hide-header>
    <img @click="closeBulkLinkModal()" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3>
          <template>
            Bulk Import Links
          </template>
        </h3>
      </div>


      <b-dropdown ref="campaigns_bulk_import" right
                  :disabled="[steps.REVIEW, steps.MAP_ATTRIBUTES].includes(step)"
                  class="dropdown-menu-right default_style_dropdown" :no-caret="true">
        <!--d-flex align-items-center-->
        <div @click="fetchCampaignOnDropdown" :class="{'input-field-error': validations.campaign}"
             class="dropdown_header br-10 rounded_shape d-flex align-items-center"
             slot="button-content"
             data-cy="select-camp">
          <div class="content">
            <div class="name">
              <p v-if="!selectedCampaign.name">Select your campaign</p>
              <p v-else>{{ limitTextLength(selectedCampaign.name, 45) }}</p>
            </div>
          </div>
          <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
        </div>
        <ul id="bulk-campaign-scroll-dropdown"
            @scroll="lazyScroll('bulk-campaign-scroll-dropdown','fetchCampaignsList',1)"
            class="clear inner">
          <template
            v-if="getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7 || getCampaigns.search">
            <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
              <input placeholder="Search for campaign"
                     type="text"
                     class="search_input_block_for_dropdown" :value="getCampaigns.search"
                     @input="debounceCampaignDropdownSearch" data-cy="search-camp"> <i
              class="fal fa-search"></i>
            </div>
            <hr>
          </template>
          <template v-if="getCampaignsList && getCampaignsList.length">
            <li class="list_item_li" @click="$refs.campaigns_bulk_import.visible = false"
                v-for="cta in getComponentCampaignList"
                :key="cta._id"
                @click.prevent="selectCampaignForBulkLink(cta),$refs.campaigns_bulk_import.hide(true)"
                v-if="cta.name" data-cy="camps">

              <div class="d-flex align-items-center profile__box">
                <div class="avatar">
                  <div class="brand_img"
                       :style="brandImageLink(cta.brand.avatar)"></div>
                </div>
                <div class="content">
                  <div class="name">
                    {{ limitTextLength(cta.name, 21) }}
                  </div>
                </div>
              </div>
            </li>
            <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
              <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
            </li>
          </template>
          <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
          <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length"
              class=" no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}
          </li>
          <li v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length" class=" no_text_li">
            {{ searchFiltersMessages('campaigns').empty_list_dropdown }}
          </li>
        </ul>
      </b-dropdown>
      <span class="input-error" v-if="validations.campaign">{{ messages.campaign }}</span>

      <div class=" mt-3 w-100 with_border toggle_btn_link">
        <div class="w-100">
          <a @click.prevent="showCsv()"
             class="btn_link" :class="{'active_tab': importCsv}">
            Import CSV
          </a>
          <a @click.prevent="showCsv(false)"
             class="btn_link" :class="{'active_tab': !importCsv }">
            Bulk Links
          </a>

        </div>
      </div>
      <p class="csv_format">Here's how your CSV file should look like. <a
        href="https://storage.googleapis.com/replug-assets/web_app/bulk_links.csv">Download CSV file template.</a></p>

      <div v-if="!isFallbackCtaEnabled()" class="alert mt-3 p-2 mb-0 alert-warning" role="alert">
        <p style="color: #856404;"><i class="fal fa-exclamation-triangle"></i> If destination URL doesn't allow iFrame, enable summary page as a fallback from Settings > Miscellaneous.</p>
      </div>

      <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box" v-if="!importCsv">
        <div class="content">
          <div class="url">Links</div>
          <div class="name">
            <div class="validation_input">
                <textarea placeholder="Enter the URL e.g https://techcrunch.com"
                          v-model="links" type="text"
                          rows="10"
                          :class="{'input-field-error': validations.links}"
                ></textarea>
              <span class="input-error" v-if="validations.links">{{ messages.links }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class=" mt-3 mb-3 d-flex align-items-center profile__box" v-if="importCsv">

        <!-- select file -->
        <div class="links_bulk_import" v-if="step===steps.SELECT_FILE">
          <div class="header">
            <p>Select File <small class="text-secondary"><em>(Maximum CSV upload size: 50,000 records.)</em></small></p>
          </div>
          <div class="body-section">
            <div class="upload_csv">
              <i class="fas fa-file-csv"></i>
              <div class="name mt-3">
                <div class="validation_input text-center">
                  <input :disabled="importCsvLoader" @change="storeCSV($event)" type="file"
                         accept=".csv"
                         id="bio-avatar"
                         class="d-none" style="display: none;">
                  <label for="bio-avatar" class="btn-small cursor_pointer btn---cta btn-blue btn-round"
                         :class="{'uploading': importCsvLoader}">
                    <span class="d-flex">Upload<clip-loader class="d-inline-block ml-1" v-if="importCsvLoader"
                                                            size="14px" color="#3988fe"></clip-loader></span>
                  </label>
                </div>
              </div>
              <div class="text-center upload-success">
                <span v-if="isFileUploaded">CSV file has been successfully uploaded with {{totalRecordsInCsv}} records.</span>
              </div>
            </div>
          </div>
          <bulk-import-progress :totalRecordsInCsv="totalRecordsInCsv" :isFileUploaded="isFileUploaded" :step="step" :disableNext="!isFileUploaded"
                                @next="nextMapAttributes"></bulk-import-progress>

        </div>
        <!-- map attributes -->
        <div class="links_bulk_import" v-else-if="step===steps.MAP_ATTRIBUTES">
          <div class="header">
            <p>Map Attributes</p>
          </div>
          <div class="body-section" v-if="indexColumns.length > 0 && !!csv_url">
            <div class="row">
              <div class="col-md-6 ">
                <div class="input_icon_text align-items-center profile__box">
                  <div class="content">
                    <div class="name">
                      <div class="validation_input">
                        <span>Select column for links</span>
                        <select name="" v-model="linkIndex"
                                :class="{'input-field-error': validations.campaign}">
                          <option v-for="(col, index) in indexColumns" :value="index">{{ col }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="input_icon_text align-items-center profile__box">
                  <div class="content">
                    <div class="name">
                      <div class="validation_input">
                        <span>Select column for slug</span>
                        <select name="" v-model="slugIndex"
                                :class="{'input-field-error': validations.campaign}">
                          <option v-for="(col, index) in indexColumns" :value="index">{{ col }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Show this when background processing is implemented in backend -->
            <div class="header pl-0 mt-3">
              <p>Specifications</p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <p>Run the import process in the background</p>
                    <i class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="">
                      <div class="tool_tip_box">
                        <div class="inner_box">
                          <p>You won't be able to edit slugs before starting the import process</p>
                        </div>
                      </div>
                    </i>
                  </div>
                  <label class="switch">
                    <input type="checkbox" @change="disableSpecOptions()" v-model="runInBackground">
                    <div class="slider round"></div>
                  </label>
                </div>
                <div v-if="isTypeCTA(selectedCampaign._id) && !this.isBridgeCTACampaign()" class="px-2 py-2 mt-1" style="background-color: aliceblue; border-radius: 8px;">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <p :style="specStyle()">{{isFallbackCtaEnabled() ? 'Notify via email of the invalid links.' : 'Notify iframe issues via Email' }}</p>
                      <i :style="specStyle()" class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="">
                        <div class="tool_tip_box">
                          <div class="inner_box">
                            <p>Links that have Iframe issue will not be imported and shall be notified via Email</p>
                          </div>
                        </div>
                      </i>
                    </div>
                    <label class="switch">
                      <input type="checkbox" :disabled="!runInBackground"
                             v-model="notifyIframeIssue">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>

                <div class="px-2 py-2 mt-1" style="background-color: aliceblue; border-radius: 8px;">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <p :style="specStyle()">Assign new slug in case of a duplicate slug</p>
                      <i :style="specStyle()" class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="">
                        <div class="tool_tip_box">
                          <div class="inner_box">
                            <p>Duplicate slugs shall be replaced with new slugs, discarded otherwise.</p>
                          </div>
                        </div>
                      </i>
                    </div>
                    <label class="switch">
                      <input type="checkbox" :disabled="!runInBackground"
                             v-model="newSlugForDuplicate">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>

                <div class="px-2 py-2 mt-1" style="background-color: aliceblue; border-radius: 8px;">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <p :style="specStyle()">Assign new slug in case of an invalid slug</p>
                      <i :style="specStyle()" class="far fa-question-circle ml-1 custom_tooltip tag_tooltip" title="">
                        <div class="tool_tip_box">
                          <div class="inner_box">
                            <p>Slugs that contain alphanumeric values including hyphen shall be replaced with a new
                              slug, discarded otherwise.</p>
                          </div>
                        </div>
                      </i>
                    </div>
                    <label class="switch">
                      <input type="checkbox" :disabled="!runInBackground"
                             v-model="newSlugForInvalid">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <bulk-import-progress
            :isFileUploaded="isFileUploaded" :step="step"
            :disableNext="runInBackground"
            :totalRecordsInCsv="totalRecordsInCsv"
            @next="nextReviewAndImport"
            :showPrevious="true"
            @previous="updateStep(steps.SELECT_FILE)"></bulk-import-progress>
        </div>
        <!-- review and import-->
        <div class="links_bulk_import" v-else-if="step===steps.REVIEW">
          <div class="header">
            <div class="row">
              <div class="col-md-3"><p>Review & Imports</p></div>
              <div class="col-md-9 d-flex align-items-center justify-content-end">
                <div>Total Links : {{ bulkCSVLinkStats.totalLinks }}</div>
                <div class="px-2">|</div>
                <div>Total Duplicates : {{ bulkCSVLinkStats.totalDuplicates }}</div>
                <div class="px-2">|</div>
                <div>Total Errors : {{ bulkCSVLinkStats.totalErrors }}</div>
              </div>
            </div>
          </div>
          <div class="body-section">
            <div class="table_container">
              <table width="100%" class="table list-content">
                <thead class=" ">
                <tr>
                  <th width="40%">CUSTOM DOMAIN</th>
                  <th width="30%">SLUG</th>
                  <th width="10%">STATUS</th>
                </tr>
                </thead>
                <tbody class="list-content-body">
                <tr v-if="loading">
                  <td colspan="7">
                    <clip-loader
                      :size="'20px'"
                      :color="'#45a2ff'"
                    ></clip-loader>
                  </td>
                </tr>
                <template
                  v-else-if="generatedLinks && generatedLinks.length > 0">
                  <tr v-for="(item,index) of generatedLinks">
                    <td v-tooltip="item.link">{{ item.custom_domain }}</td>
                    <td class="slug_column">
                      <div v-if="item.edit">
                        <div class="inline_editor">
                          <input maxlength="50" type="text"
                                 :value="item.edit_value"
                                 :ref="'editSlugField'+index"
                                 :disabled="item.statusText===bulkCSVLinkStatus.iframe_error"
                                 @input="debounceSlugInputField($event,item)"
                                 @blur="focusOutSlugInput(item,false,index)"
                                 placeholder="Enter your slug">
                          <clip-loader v-if="item.loader" class="icon" style="right: 5px;" :size="'15px'"
                                       :color="'#45a2ff'"></clip-loader>
                        </div>
                      </div>
                      <div v-else>
                        <div :title="isTrialUserObj.message" class="row m-auto">
                          <span v-tooltip="item.slug" class="ellipsis_slug">{{ item.slug }}</span>
                          <clip-loader v-if="item.loader" class="icon ml-1" :size="'10px'"
                                       :color="'#45a2ff'"></clip-loader>
                          <span v-show="!isTrialUserObj.isTrial && item.statusText!==bulkCSVLinkStatus.iframe_error
                            && !item.loader"
                                @click="focusOutSlugInput(item,true,index)" class="icon ml-1 cursor-pointer"><i
                            class="far fa-pencil"></i></span></div>

                      </div>
                    </td>
                    <td>
                      <div v-if="!item.loader">
                      <span v-if="item.statusText===bulkCSVLinkStatus.valid" class="status green "
                            v-tooltip="'Slug is valid.'">Success</span>
                        <span
                          v-else-if="[bulkCSVLinkStatus.duplicate, bulkCSVLinkStatus.duplicate_in_list].indexOf(item.statusText) !== -1"
                          class="status red "
                          v-tooltip="'Slug should be unique.'">Duplicate</span>
                        <span v-else-if="item.statusText===bulkCSVLinkStatus.iframe_error"
                              class="status info "
                              v-tooltip="'Iframe is not allowed for this url.'">Iframe</span>
                        <span v-else-if="item.statusText===bulkCSVLinkStatus.meta_invalid" class="status warn "
                              v-tooltip="'URL cannot be validated. Please enter valid URL'">Invalid</span>
                        <span v-else-if="item.statusText===bulkCSVLinkStatus.invalid" class="status warn "
                              v-tooltip="'Slug should only contain alphanumeric values including hyphen(-).'">Invalid</span>
                        <span v-else class="status warn "
                              v-tooltip="slugMessagesObj.slug_length">Invalid</span>
                      </div>
                      <skeleton v-else></skeleton>
                    </td>
                  </tr>
                </template>
                </tbody>

              </table>
            </div>
          </div>
          <bulk-import-progress
            :isFileUploaded="isFileUploaded" :step="step"
            :showNext="false"
            :totalRecordsInCsv="totalRecordsInCsv"
            :showPrevious="true"
            @previous="updateStep(steps.MAP_ATTRIBUTES)"></bulk-import-progress>
        </div>

      </div>


      <div class="tag_input mt-3 mb-3" v-on-clickaway="closeTag">
        <div class="tag_input_inner">
          <div class="   input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">Tags
                <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                  <div class="tool_tip_box">
                    <div class="inner_box">
                      <p>Tags are for your own reference only and not visible to public.
                        They help you easily sort, identify and analyze your links and
                        campaigns later on.</p>
                    </div>
                  </div>
                </i>
              </div>

              <div class="name">
                <div class="validation_input">
                  <input type="text" placeholder="Examples: christmas, blackfriday, evergreen"
                         @focus="addLinkTag($event,getLinks.linkTag)"
                         @blur="blurFocusTag()"
                         v-on:keyup="addLinkTag($event,getLinks.linkTag)"
                         class="" v-model="getLinks.linkTag">

                </div>
              </div>
            </div>
          </div>
          <ul class="tag_list_dd"
              v-if="getLinks.linkTag.length > 0 && checkTagNotExist(getLinks.linkTag,getSaveLink.tags)">
            <template v-if="getLinks.suggestedTags.length > 0">
              <li class="old_item" v-for="(tag,index) in getLinks.suggestedTags">
                <span class="text" @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
              </li>
            </template>
            <li v-else class="new_item">
                <span class="text" @click.prevent="addLinkTag($event,getLinks.linkTag,true)">
                  Add new tag "{{ getLinks.linkTag }}"</span>
            </li>
          </ul>
          <ul class="tag_list_dd"
              v-else-if="getLinks.linkTag.length === 0 && getLinks.focus=== true && getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
            <li class="old_item" v-for="(tag,index) in getLinks.suggestedTags">
                            <span class="text"
                                  @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
            </li>
          </ul>

        </div>
        <div class="tag-item-list mt-2 mb-2 " v-if="getSaveLink.tags.length > 0">

          <ul>
            <li v-for="tag in getSaveLink.tags" v-if="getTagName(tag) && tag">
              <span class="text">{{ getTagName(tag) }}</span><span
              @click.prevent="removeTag(tag)" class="cross_icon"></span>
            </li>

          </ul>
        </div>
      </div>

      <div class=" btn_block text-left">
        <button @click.prevent="bulkLinkUpload()"
                :disabled="disableImportBtn()"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--left btn-bold">
          <clip-loader v-if="loading" :size="size" :color="color"></clip-loader>
          <span>Import</span>

        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="closeBulkLinkModal()"><span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>

</template>
<script>
import {mapGetters} from 'vuex'
import http from '@/mixins/http-lib'
import {isBulkCSVSlugExist} from '@/config/api'
import {bulkCSVLinkStatus, csvExportSteps} from '@/common/constants'
import {fetchBulkLinkPreviewURL} from '@/config/urls/link/link'
import {searchFiltersMessages, slugMessages} from '@/common/attributes'

export default ({
  data () {
    return {
      steps: csvExportSteps,
      loading: false,
      // process attributes
      runInBackground: false,
      notifyIframeIssue: false,
      newSlugForDuplicate: false,
      newSlugForInvalid: false,
      // import
      csv_url: '',
      step: csvExportSteps.SELECT_FILE,
      selectedCampaign: {},
      linkIndex: 0,
      slugIndex: 1,
      // validations
      validations: {
        campaign: false,
        links: false
      },
      messages: {
        campaign: 'Please select a campaign',
        links: 'Links cannot be empty, please add links'
      },
      importCsv: true,
      importCsvLoader: false,
      indexColumns: [],
      isFileUploaded: false,
      generatedLinks: [],
      links: [],
      domain: '',
      bulkCSVLinkStatus: bulkCSVLinkStatus,
      bulkCSVLinkStats: {
        totalLinks: 0,
        totalDuplicates: 0,
        totalErrors: 0
      },
      isTrialUserObj: {},
      slugMessagesObj: slugMessages,
      searchFiltersMessages: searchFiltersMessages,
      totalRecordsInCsv: 0
    }
  },
  components: {
    BulkImportProgress: () => import('@/views/pages/links/dialogs/BulkImportProgress.vue')
  },
  computed: {
    ...mapGetters([
      'getLinks',
      'getSaveLink',
      'getSubscription',
      'getCampaignLoaders',
      'getCampaigns',
      'getComponentCampaignList',
      'getCampaignsList'
    ])
  },
  created () {
    this.isTrialUserObj = this.isTrialUser(this.getSubscription)
  },
  methods: {
    specStyle () {
      return this.runInBackground ? {} : {color: 'darkgray'}
    },
    disableSpecOptions () {
      if (!this.runInBackground) {
        this.notifyIframeIssue = false;
        this.newSlugForDuplicate = false;
        this.newSlugForInvalid = false;
      }
    },
    disableImportBtn () {
      if (this.loading) {
        return true
      }
      return this.importCsv && !this.runInBackground && this.step !== this.steps.REVIEW
      // && !this.reviewAndImportValidation
    },
    async bulkLinkUpload () {
      this.validations.campaign = this.requiredCheck(this.selectedCampaign._id)
      if (!this.importCsv) {
        this.validations.links = this.requiredCheck(this.links)
        let result = Object.keys(this.validations).every(k => this.validations[k] === false)
        if (!result) {
          return;
        }

        this.loading = true
        await this.$store.dispatch('bulkStoreLink', {
          campaign: this.selectedCampaign._id,
          links: this.links
        })
        this.loading = false
        this.closeBulkLinkModal()
        return
      }

      // to process csv file when the loader is off
      if (!this.importCsvLoader) {
        this.validations.links = this.requiredCheck(this.csv_url)
        let result = Object.keys(this.validations).every(k => this.validations[k] === false)
        // if validations failed
        if (!result) {
          return
        }
        // pick valid links and a couple of properties from the links
        const generatedLinks = this.generatedLinks
          .filter(link => link.statusText === bulkCSVLinkStatus.valid)
          .map(({slug, link, iframe_allowed, information}) => ({slug, link, iframe_allowed, information}))
        const {
          csv_url, linkIndex, slugIndex, runInBackground, notifyIframeIssue, newSlugForDuplicate,
          newSlugForInvalid
        } = this
        // process starts
        await this.$store.dispatch('bulkCSVLink', {
          call_to_action_id: this.selectedCampaign._id,
          url: csv_url,
          generatedLinks,
          linkIndex,
          slugIndex,
          runInBackground,
          notifyIframeIssue,
          newSlugForDuplicate,
          newSlugForInvalid,
        });
        this.closeBulkLinkModal()
      }
    },
    focusOutSlugInput (generatedCSVLinkObject, check, index) {
      generatedCSVLinkObject.edit = check
      setTimeout(() => {
        const refs = this.$refs['editSlugField' + index]
        if (refs && refs[0]) {
          refs[0].focus()
        }
      })
    },
    /**
     * In this we are checking the slug status like 'invalid','valid','duplicate','duplicate_in_list'
     * @param event
     * @param generatedCSVLinkObject
     */
    debounceSlugInputField (event, generatedCSVLinkObject) {
      //  if slug is empty then return
      if (!event.target.value) {
        this.$store.dispatch('toastNotification', {message: 'Slug should not be empty.', type: 'error'})
        this.getBulkCSVLinkStats()
        return
      }

      //we are setting current slug status and its new edited value
      generatedCSVLinkObject.loader = true
      generatedCSVLinkObject.edit_value = event.target.value
      generatedCSVLinkObject.slug = event.target.value
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {

        if (generatedCSVLinkObject.slug && this.checkSlugLength(generatedCSVLinkObject.slug)) {
          generatedCSVLinkObject.statusText = bulkCSVLinkStatus.slug_length
          generatedCSVLinkObject.loader = false
          this.getBulkCSVLinkStats()
          return
        }

        // on the very first we will check slug regex if will set
        // statusTest : invalid if regex fail

        const regex = /^[a-zA-Z0-9-]*$/
        if (!regex.test(generatedCSVLinkObject.edit_value)) {
          generatedCSVLinkObject.statusText = bulkCSVLinkStatus.invalid
          generatedCSVLinkObject.loader = false
          this.getBulkCSVLinkStats()
          return
        }

        // if slug exists, check within list
        const associativeSlugArray = []
        this.generatedLinks.forEach((obj) => {
          if (obj.statusText !== bulkCSVLinkStatus.iframe_error) {
            // checking slug is new slug is exist in associativeSlugArray
            if (Object.keys(associativeSlugArray).indexOf(obj.edit_value) === -1) {
              // it means that slug has not been spotted in the array before
              associativeSlugArray[obj.edit_value] = 0
              // check if the slug is not invalid
              // based on regex and backend, simply consider it valid as its no more duplicate
              if ([bulkCSVLinkStatus.invalid, bulkCSVLinkStatus.duplicate, bulkCSVLinkStatus.iframe_error].indexOf(obj.statusText) === -1) {
                obj.statusText = bulkCSVLinkStatus.valid
              }
              generatedCSVLinkObject.loader = false
            } else {
              // increase the number of occurrences and consider it duplicate
              associativeSlugArray[obj.edit_value]++
              obj.statusText = bulkCSVLinkStatus.duplicate_in_list
              obj.loader = false
            }
          }
        })

        /**
         * check status of newly edited value
         * if status is invalid or duplicate in list, simply show result without extra server check
         * we will return if slug is still duplicate in list
         */
        if ([bulkCSVLinkStatus.duplicate_in_list, bulkCSVLinkStatus.iframe_error].indexOf(generatedCSVLinkObject.statusText) !== -1) {
          this.getBulkCSVLinkStats()
          return
        }
        /**
         * if invalid slug and no duplicate is exist in list then we will check it in database
         * @type {{domain: {}, slug}}
         */

        const payload = {
          slug: generatedCSVLinkObject.edit_value,
          domain: this.domain
        }
        await http.post(isBulkCSVSlugExist, payload).then(res => {
          generatedCSVLinkObject.statusText = res.data.statusText
          generatedCSVLinkObject.loader = false
          this.getBulkCSVLinkStats()
        }).catch(() => {
          return null
        })

        generatedCSVLinkObject.loader = false
      }, 600)

    },
    selectCampaignForBulkLink (campaign) {
      this.selectedCampaign = {
        _id: campaign._id,
        name: campaign.name,
      }
      this.validations.campaign = false
    },
    nextMapAttributes () {
      this.validations.campaign = !this.selectedCampaign._id;
      if (!this.validations.campaign) {
        this.updateStep(this.steps.MAP_ATTRIBUTES);
      }
    },
    async nextReviewAndImport () {
      this.updateStep(this.steps.REVIEW)
      this.bulkCSVLinkStats = {
        totalLinks: 0,
        totalDuplicates: 0,
        totalErrors: 0
      }
      this.loading = true
      const response = await this.$store.dispatch('bulkProcessManuallyLink', {
        url: this.csv_url,
        call_to_action_id: this.selectedCampaign._id,
        linkIndex: this.linkIndex,
        slugIndex: this.slugIndex,
      })
      this.loading = false
      if (!response) {
        return
      }
      this.generatedLinks = response.generatedLinks.map(el => (el.edit = false, el))
      this.domain = response.domain
      if (this.isTypeCTA(this.selectedCampaign._id) && !this.isBridgeCTACampaign()) {
        await this.checkIframe()
      }
      await this.getBulkCSVLinkStats()
    },
    updateStep (step) {
      this.step = step
    },
    /**
     * Fetch unique urls from the array
     * Check if they can be embedded in an iframe
     * When we get response, update status of all objects with that url
     */
    async checkIframe () {
      // fetching unique links from the csv response
      let unique = {}
      this.generatedLinks.forEach(elem => {
        elem.loader = true
        if (!unique[elem.link]) {
          unique[elem.link] = true
        }
      })
      unique = Object.keys(unique)
      const cta = this.selectedCampaign._id

      // send iframe call in chunks of 10
      const chunkSize = 10;
      for (let i = 0; i < unique.length; i += chunkSize) {
        const urls = unique.slice(i, i + chunkSize);
        http.post(fetchBulkLinkPreviewURL, {urls, cta}).then(
          response => {
            if (this.processAjaxResponse(response)) {
              // updating all urls that have this link
              if (!response.data) {
                return;
              }

              // convert array to an object with keys of unique url
              const data = response.data.reduce((curr, prev) => (curr[prev.original_url] = prev, curr), {})

              // set status for the links
              // TODO: We may get status false for malicious URLs, show malicious to the user instead of iframe_error
              this.generatedLinks
                .filter(el => urls.includes(el.link))
                .forEach(el => {
                  el.loader = false

                  if(this.isFallbackCtaEnabled()) {
                    el.information = data[el.link]['information']
                    el.iframe_allowed = data[el.link]['iframe_allowed']
                  }

                  if (this.isFallbackCtaEnabled() && !data[el.link].status) {
                    el.statusText = bulkCSVLinkStatus.meta_invalid
                  }

                  if (!this.isFallbackCtaEnabled() && !data[el.link].status) {
                    el.statusText = bulkCSVLinkStatus.iframe_error
                  }

                  if (data[el.link].status) {
                    el.link = data[el.link]['url']
                  }
                })
            }
            this.getBulkCSVLinkStats()
          })
      }
    },
    async getBulkCSVLinkStats () {
      this.bulkCSVLinkStats = {
        totalLinks: 0,
        totalDuplicates: 0,
        totalErrors: 0
      }
      this.generatedLinks.forEach((obj) => {
        this.bulkCSVLinkStats.totalLinks++
        if ([bulkCSVLinkStatus.slug_length, bulkCSVLinkStatus.invalid, bulkCSVLinkStatus.iframe_error].includes(obj.statusText)) {
          this.bulkCSVLinkStats.totalErrors++
        }
        if ([bulkCSVLinkStatus.duplicate, bulkCSVLinkStatus.duplicate_in_list].includes(obj.statusText)) {
          this.bulkCSVLinkStats.totalDuplicates++
        }
      })
    },
    showCsv (value = true) {
      this.importCsv = value
    },
    async storeCSV ($event) {
      // for testing purpose - hardcode
      // 55k+
      /*this.csv_url = 'https://storage.googleapis.com/replug-assets/web_app/56c8cc50-adcd-4f22-b35b-6a9a03480aff.csv'
      // sample
      // this.csv_url = 'https://storage.googleapis.com/replug-assets/web_app/9df70ac1-9d6c-4a31-b6f3-ab15e3e4f910.csv'
      this.isFileUploaded = true
      this.indexColumns = ['URL', 'SLUG']
      return*/
      this.importCsvLoader = true
      let res = await this.$store.dispatch('uploadCSV', $event)

      if (!res) {
        this.$store.dispatch('toastNotification', {message: 'Unexpected error occurred.', type: 'error'})
        return;
      }

      if (!res.data.status) {
        this.$store.dispatch('toastNotification', {message: res.data.message, type: 'error'})
        this.importCsvLoader = false
        return;
      }

      this.csv_url = res.data.url
      this.totalRecordsInCsv = res.data.records
      this.isFileUploaded = true
      let header = await this.$store.dispatch('fetchCsvHeaders', this.csv_url)
      if (header && header.data.status) {
        this.indexColumns = header.data.data
      }
      this.importCsvLoader = false
    },
    closeBulkLinkModal () {
      this.$bvModal.hide('bulk-import')

      this.loading = false
      this.runInBackground = false
      this.notifyIframeIssue = false
      this.newSlugForDuplicate = false
      this.newSlugForInvalid = false
      this.csv_url = ''
      this.step = csvExportSteps.SELECT_FILE
      this.selectedCampaign = {}
      this.validations = {
        campaign: false,
        links: false
      }
      this.importCsv = true
      this.importCsvLoader = false
      this.indexColumns = []
      this.isFileUploaded = false
      this.generatedLinks = []
      this.domain = ''
      this.bulkCSVLinkStats = {
        totalLinks: 0,
        totalDuplicates: 0,
        totalErrors: 0
      }
      this.links = []
    },
    closeTag () {
      this.getLinks.focus = false
    }
  }
})
</script>

<style scoped lang="less">
.uploading {
  background: rgba(57, 136, 254, 0.1);
  transition: all 0.2s linear !important;
  color: #3988fe;

  span {
    color: #3988fe;
  }

  &:hover {
    box-shadow: none;
  }
}

.csv_format {
  margin-top: 8px;
  margin-left: 4px;
  font-size: 12px;
  color: rgba(49, 58, 81, 0.6);

  a {
    color: #1f216e;
  }
}

.btn_link {
  min-width: 352px !important;
}

.ellipsis_slug {
  width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
