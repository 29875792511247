<template>
  <div class="head_left">

    <!-- <button
      v-if="getProfile.policy.can_add_links"
      @click.prevent="createNewLink()"
      :class="{'btn--effect-pulse': getProfile.onboarding === false }"
      class=" btn---cta btn-blue with-shadow btn-round   btn-bold"
    >
      <span>New Link</span>
      <span class="icon_right">
        <i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
      </span>
    </button> -->
    <ui-button
      :type="'add'"
      :color="'#ffffff'"
      :bgColor="'#3988fe'"
      :text="'New Link'"
      :icon="'fal fa-plus'"
      :iconPosition="'right'"
      :iconBackground="'rgba(31, 33, 110, 0.35)'"
      :iconBorderRadius="'50%'"
      :pulseEffect="getProfile.onboarding === false"
      v-if="getProfile.policy.can_add_links"
      v-on:click="createNewLink()"
    />

    <!--<button v-if="getLinks.items.length > 0" @click.prevent="$bvModal.show('export-links')"-->
    <!--class="btn-&#45;&#45;cta btn-border btn-round mr-2  btn-bold">-->
    <!--<span>CSV Export</span>-->
    <!--</button>-->
    <!--<button v-if="getLinks.archive" @click.prevent="showLinksArchive(false)"-->
    <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
    <!--<span>Show Active Links</span>-->
    <!--</button>-->
    <!--<button v-else @click.prevent="showLinksArchive(true)"-->
    <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
    <!--<span>Show Archived Links</span>-->
    <!--</button>-->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  methods: {
    ...mapActions(['fetchLinks']),
    // showLinksArchive (status) {
    //   this.$store.commit(linkTypes.SET_LINKS_ARCHIVE_VALUE, status)
    //   this.fetchLinks()
    // }
  },
  computed: {
    ...mapGetters([
      'getLinks', 'getProfile'
    ])
  }
})
</script>
