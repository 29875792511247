<template>
  <tbody>
  <tr>
    <td colspan="9" class="">
      <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
          getFilteredMessage('link', getLinks.search, getLinks.date.label, getLinks.archive, getLinks.brand, getLinks.campaign, getLinks.tags)
        }}</p>
    </td>
  </tr>
  </tbody>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getLinks'])
  }
})
</script>
