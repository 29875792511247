<template>


  <div>
    <div class="inline_editor" v-if="link.addTag">
      <clip-loader v-if="link.tagLoader" class="icon" style="right: 30px;"
                   :size="size_small" :color="color"></clip-loader>

      <div class="relative tags_dd" v-else v-on-clickaway="blurTag">
        <input maxlength="50"
               v-on:keyup="addLinkListingTag($event,link._id,link.tagValue.trim(),index)"
               @focus="addLinkListingTag($event,link._id,link.tagValue.trim(),index)"
               @blur="blurFocusTag(index)"
               @click="setFocus()"
               type="text" placeholder="Enter tag name..."
               v-model="link.tagValue">

        <span class="icon red"
              @click.prevent="setAddTagStatus(index,false)"><i
          class="fal fa-times"></i></span>
        <span class="icon green" v-if="link.tagValue.length > 0"
              @click.prevent="addLinkListingTag($event,link._id,link.tagValue.trim(),index,true)"><i
          class="far fa-check"></i></span>

        <div class="tag_list_dd w-auto"
             v-if="link.tagValue.length > 0 || focus === true">
          <ul class=""
              v-if="link.tagValue.length > 0 && checkTagNotExist(link.tagValue,link.tags) && focus === true">
            <template v-if="getLinks.suggestedTags.length > 0">
              <li class="old_item"
                  v-for="tag in getLinks.suggestedTags">
                                            <span class="text"
                                                  @click.prevent="addLinkListingTag($event,link._id,getTagName(tag._id),index,true)">
                                                {{ getTagName(tag._id) }}
                                            </span>
              </li>
            </template>
            <li v-else class="new_item">
                                        <span class="text"
                                              @click.prevent="addLinkListingTag($event,link._id,link.tagValue.trim(),index,true)">
                                            Add new tag "{{ link.tagValue }}"
                                        </span>
            </li>

          </ul>
          <ul class=""
              v-else-if="link.tagValue.length === 0 && focus === true">
            <li class="old_item"
                v-for="tag in getLinks.suggestedTags">
                                            <span class="text"
                                                  @click.prevent="addLinkListingTag($event,link._id,getTagName(tag._id),index,true)">
                                                {{ getTagName(tag._id) }}
                                            </span>
            </li>
          </ul>
        </div>

      </div>

    </div>

    <div class="tag-item-list   " v-else>
      <ul>
        <template v-for="(tag,tag_index) in link.tags"
                  v-if="getTagName(tag) != '' && getTagName(tag) && getTagName(tag).length > 0">
          <li v-if="tag_index < 3 " class="max-width-100">
            <span class="text text-truncate-tag">{{ getTagName(tag) }}</span>
            <span class="cross_icon"
                  @click.prevent="removeLinkTag(link._id,index,tag,false,tag_index)"></span>
          </li>
        </template>

        <li v-if="link.tags && link.tags.length > 3" class="custom_tooltip">
          <span class="text">+{{ link.tags.length - 3 }} more</span>

          <div class="tool_tip_box">
            <div class="inner_box">
              <template v-for="(tag,tag_index) in link.tags"
                        v-if="getTagName(tag) != '' && getTagName(tag) && getTagName(tag).length > 0">

                      <span v-if="tag_index >= 3" class="ml-2 max-width-100">
                        <span class="text text-truncate-tag">{{ getTagName(tag) }}</span>
                        <span class="cross_icon"
                              @click.prevent="removeLinkTag(link._id,index,tag,false,tag_index)"></span>
                      </span>
              </template>

            </div>
          </div>
        </li>

        <li v-tooltip.top-center="'Add Tag'" class="add_tag"
            @click.prevent="setAddTagStatus(index,true)">
          <span class="fal fa-plus add_icon"></span>
          <!--<span class="text">add</span>-->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { linkTypes } from '@/state/modules/mutation-types'

export default {
  data () {
    return {
      focus: true,
    }
  }
  ,
  props: ['link', 'index'],
  computed: {
    ...mapGetters([
      'getLinks'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions([]),
    blurTag () {
      this.focus = false
    },
    setFocus () {
      this.focus = true
    },
    setAddTagStatus (index, status) {
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
      this.$set(this.getLinks.items[index], 'tagValue', '')
      this.$set(this.getLinks.items[index], 'addTag', status)
    },
  }
}
</script>

<style scoped lang="less">
.max-width-100 {
  max-width: 150px;
}
.text-truncate-tag {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag_list_dd{
  overflow-y: auto !important;
}
</style>
