<template>
  <div class="links_component">
    <confirmation-box greenColor="blue" class="archiveLink archiveBrand" id="archiveLink"
                      :confirmCallback="archiveLink" confirmText="Yes" cancelText="No">

      <template slot="header">
        <h2 v-if="getModalData.status">Archive Link</h2>
        <h2 v-else>Unarchive Link</h2>
      </template>

      <template slot="description">

        <p v-if="getModalData.status">Link will be archived but it will still be functional.</p>
        <p v-else style="margin-bottom: 15px;">All links inside this campaign will also be unarchived, Do you
          want to continue?</p>

        <p class="small_sub_text">
          Do you want to continue?
        </p>

        <p class="checkbox gray_checkbox">
          <input id="dontShowAgain" v-model="getArchiveStatus.status" type="checkbox">
          <label for="dontShowAgain">Do not show this message again.</label>
        </p>

      </template>

      <template slot="footer">

      </template>

    </confirmation-box>
    <confirmation-box greenColor="blue" class="archiveLink archiveBrand" id="linkLimitConfirm"
                      :confirmCallback="allowBulkLimits" confirmText="Yes" cancelText="No">

      <template slot="header">
        <h2>Confirm Bulk Import</h2>
      </template>

      <template slot="description">

        <p>Only 1000 links can be processed using a Bulk Import.</p>

        <p class="small_sub_text">
          Do you want to proceed?
        </p>

      </template>

      <template slot="footer">

      </template>

    </confirmation-box>
    <transition name="slide">
      <!--<sidebar></sidebar>-->

      <div class="container">
        <div class="modal fade normal_modal form_modal upgrade_model_small" id="modalBulkImport1" tabindex="-1"
             role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <!--<div class="modal-header">-->
              <!--<button type="button " class="close" data-dismiss="modal" aria-hidden="true">&times;-->
              <!--</button>-->

              <!--<h4 class="modal-title">Upgrade Plan</h4>-->

              <!--</div>-->

              <div class="modal-body">

                <!-- Create Client Form -->

                <form class="basic_form clear text-center">
                  <p>This feature is available in the Pro plan. You are on the Basic (trial) plan
                    right now.</p>

                  <div class=" text-center">
                    <button class="btn--cta btn-blue btn-round  ">
                      <span>Upgrade to the Pro (Trial) Plan for Free</span>
                    </button>
                  </div>

                </form>

              </div>

              <!-- Modal Actions -->
              <div class="modal-footer hide ">

                <button class="btn---cta btn-round btn-blue">
                  <span>Create Links</span>
                </button>
                <!--<button type="button" class="btn&#45;&#45;cta btn&#45;&#45;small btn-&#45;&#45;transparent " data-dismiss="modal">Close</button>-->

              </div>

            </div>
          </div>
        </div>
        <links-csv-export-modal v-if="isShowLinksCsvImportModal"></links-csv-export-modal>
        <links-bulk-import-modal ref="links_bulk_import"></links-bulk-import-modal>
        <div class="links_component_inner">

          <div class="component_head d-flex align-items-center">
            <!--head_left-->
            <links-archive-and-export></links-archive-and-export>

            <!--head_right-->
            <links-filters position="top" :tableColumns="tableColumns"></links-filters>

          </div>
          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2 class="">Your {{ getSiteDetails.agency_name }} Links</h2>
                  <p class="">
                    A {{ getSiteDetails.agency_name }} Link is a shortened, customizable link that you can create against any
                    URL
                    you visit. You can share it among your audience and track its performance by
                    examining the metrics like clicks, unique clicks, and conversion rate etc.
                  </p>
                </div>
                <div class="right more_width_500">

                  <links-filters position="bottom"></links-filters>

                </div>

                <!--                                <div class="page_limits">-->
                <!--                                    <b-dropdown right-->
                <!--                                                class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"-->
                <!--                                                :no-caret="true">-->
                <!--                                        <div class="dropdown_header rounded_shape d-flex align-items-center  "-->
                <!--                                             slot="button-content">-->
                <!--                                            <span class="text">Results Per Page</span>-->
                <!--                                            <span class="arrow_icon ml-auto">-->
                <!--                                                <i class=" fal fa-angle-down ml-3"></i>-->
                <!--                                             </span>-->
                <!--                                        </div>-->
                <!--                                        <ul class="inner results_filter_dropdown ">-->
                <!--                                            <li class="list_item_li"-->
                <!--                                                @click="$router.replace({query:{...$route.query, limit: 10}})">-->
                <!--                                                10 Results <i class="far fa-check"-->
                <!--                                                              v-if="isDefaultRouteLimit"></i>-->
                <!--                                            </li>-->
                <!--                                            <li class="list_item_li"-->
                <!--                                                @click="$router.replace({query:{...$route.query, limit: 20}})">-->
                <!--                                                20 Results <i class="far fa-check"-->
                <!--                                                              v-if="$route.query.limit && $route.query.limit === 20"></i>-->
                <!--                                            </li>-->
                <!--                                            <li class="list_item_li"-->
                <!--                                                @click="$router.replace({query:{...$route.query, limit: 50}})">-->
                <!--                                                50 Results <i class="far fa-check"-->
                <!--                                                              v-if="$route.query.limit && $route.query.limit === 50"></i>-->
                <!--                                            </li>-->
                <!--                                            <li class="list_item_li"-->
                <!--                                                @click="$router.replace({query:{...$route.query, limit: 100}})">-->
                <!--                                                100 Results <i class="far fa-check"-->
                <!--                                                               v-if="$route.query.limit && $route.query.limit === 100"></i>-->
                <!--                                            </li>-->
                <!--                                        </ul>-->
                <!--                                    </b-dropdown>-->
                <!--                                </div>-->
              </div>

              <div class="box_head d-flex align-items-center pt-0">
                <links-filters position="bottom-search"></links-filters>
              </div>

              <div class="box_content">
                <div class="selected_items_bar" v-if="getLinksBulkSelection.items.length">
                                    <span class="main_text">
                                        You have selected <strong>{{
                                        getLinksBulkSelection.items.length
                                      }} {{ getLinksText }}</strong>.
                                    </span>
                  <span class="selected_items_bar_actions"
                        @click="$store.dispatch('bulkLinksAction', 'enable')">
                                        <i class="far fa-check-circle"></i>Enable
                                    </span>
                  <span class="selected_items_bar_actions"
                        @click="$store.dispatch('bulkLinksAction', 'disable')">
                                        <i class="far fa-stop-circle"></i>Disable
                                    </span>
                  <template v-if="!getLinks.archive">
                                        <span class="selected_items_bar_actions"
                                              @click="$store.dispatch('bulkLinksAction', 'archive')">
                                        <i class="far fa-recycle"></i>Archive
                                    </span>
                  </template>
                  <template v-else>
                                        <span class="selected_items_bar_actions"
                                              @click="$store.dispatch('bulkLinksAction', 'unarchive')">
                                        <i class="far fa-recycle"></i>Unarchive
                                    </span>
                  </template>
                  <span class="selected_items_bar_actions"
                        @click="$store.dispatch('bulkLinksAction', 'delete')">
                                        <i class="far fa-trash"></i>Delete
                                    </span>
                </div>
                <div class="table_container">
                  <table class="table list-content">
                    <links-list-header :tableColumns="tableColumns"></links-list-header>

                    <tbody v-if="getLinksLoaders.retrieve">
                    <tr>
                      <td colspan="10">
                        <clip-loader :size="size" :color="color"></clip-loader>
                      </td>
                    </tr>
                    </tbody>
                    <links-list-no-results
                      v-else-if="getLinks.items.length === 0"></links-list-no-results>
                    <links-list v-else :tableColumns="tableColumns"></links-list>
                  </table>
                </div>
              </div>

            </div>

            <template v-if="!getLinksLoaders.retrieve && getLinks.totalItems > getLinks.limit">
              <pagination :clickedMethod="paginateLinks" :resultCount="getLinks.totalItems"
                          :itemsPerPage="getLinks.limit" :currentPage="getLinks.page"
                          :totalPagesNumber="getLinks.totalPages"></pagination>
            </template>

          </div>

        </div>

      </div>
    </transition>
  </div>
</template>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.selected_items_bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .main_text {
    border-radius: 4px;
    padding: 10px 10px;
    background: #ffffea;
  }

  .selected_items_bar_actions {
    padding: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffea;
    cursor: pointer;
    // text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }

    i {
      margin-right: 5px;
    }
  }
}

.results_dropdown {
  .dropdown-menu {
    width: 160px !important;
    min-width: 160px !important;
  }
}

.results_filter_dropdown {
  li {
    padding: 5px 0 !important;

    i {
      float: right;
      padding-right: 5px;
      padding-top: 3px;
    }
  }

}

</style>

<script>

import { mapActions, mapGetters } from 'vuex'

import Multiselect from 'vue-multiselect'
import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Modal from '@/misc/Modal.vue'
import Dropdown from '@/ui/Dropdown.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import LinksList from './table/LinksList'
import LinksListHeader from './table/LinksListHeader'
import LinksListNoResults from './table/LinksListNoResults'
import LinksFilters from './filters/LinksFilters'
import LinksCSVExportModal from './dialogs/LinksCSVExportModal'
import LinksBulkImportModal from './dialogs/LinksBulkImportModal'
import LinksArchiveAndExport from './filters/LinksArchiveAndExport'
import GuidedTour from '../../guided-tour/GuidedTour.vue'
import { linkTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
import moment from 'moment'
import $ from 'jquery'

export default ({
  'name': 'Links',
  'components': {
    Multiselect,
    Headerbar,
    Sidebar,
    Modal,
    Dropdown,
    ClipLoader,
    GuidedTour,
    ConfirmationBox,
    LinksList,
    LinksListHeader,
    LinksListNoResults,
    LinksFilters,
    LinksBulkImportModal,
    LinksArchiveAndExport,
    'links-csv-export-modal': LinksCSVExportModal
  },
  data: function () {
    return {
      size: '20px',
      size_small: '18px',
      color: DARK_PURPLE_LOADER_COLOR,
      tableColumns: [
        { id: 1, name: 'Original Link', status: true },
        { id: 2, name: 'Campaign', status: false },
        { id: 3, name: 'Clicks', status: true },
        { id: 4, name: 'Conv.', status: true },
        { id: 5, name: 'Conv. rate', status: true },
        { id: 6, name: 'Avg. Time', status: false },
        { id: 7, name: 'Tags', status: true },
        { id: 8, name: 'Unique Clicks', status: false }
      ],
      isShowLinksCsvImportModal: true
    }
  },

  async created () {
    this.$store.commit(linkTypes.SET_LINKS_DATE_RANGE, {
      label: ' All Time',
      value: ''
    })
    this.$set(this.getLinks, 'tags', [])
    this.$set(this.getLinks, 'campaign', [])
    await this.fetchLinks()
    await this.fetchLinkAnalytics()
    await this.fetchDomains()
    await this.fetchPixels()
    await this.fetchWidgets({ page: -1 })
    await this.fetchTags()
  },

  computed: {

    ...mapGetters([
      'getCampaigns',
      'getTagList',
      'getLinksLoaders',
      'getGuidedTour',
      'getLinks',
      'getCallToActions',
      'getLinksSelectedTag',
      'getModalData',
      'getBulkLink',
      'getLinksBulkSelection',
      'getExportLinks'
    ]),
    getLinksText () {
      return (this.getLinksBulkSelection.items.length === 1)
        ? 'link'
        : 'links'
    }

  },
  mounted () {
    let self = this
    setTimeout(function () {
      self.callLinkDatePicker()
    }, 500)
  },

  methods: {

    ...mapActions([
      'fetchLinks',
      'archiveLink',
      'setAllCallToActionsFetchStatus', 'setAllBrandsFetchStatus',
      'createQRCode',
      'renderQRCode',
      'fetchLinkAnalytics',
      'fetchDomains',
      'fetchPixels',
      'fetchWidgets',
      'fetchTags',
      'fetchCampaignsList',
      'fetchBrandList'
    ]),

    callLinkDatePicker () {
      let me = this
      $(document).ready(function () {
        $('.linkDatePicker').daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $('.linkDatePicker').on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $('.linkDatePicker').on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })
        $('.linkDatePicker').on('apply.daterangepicker', function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: ' ' + picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(linkTypes.SET_LINKS_DATE_RANGE, range)
          me.paginateLinks(1)
        })
      })
    },
    closeExportCsvModal () {
      this.isShowLinksCsvImportModal = false
      this.$bvModal.hide('export-links')
      this.getExportLinks = {
        brands: [],
        campaigns: [],
        campaigns_options: []
      }
    }

  },
  watch: {

    getLinksSelectedTag (tag) {
      let tags = this.getTagList
      if (tags) {
        if (tags.length === tag.length) {
          // this.setLinkTagsSelectAll(true) // note: commented by umair

        } else {
          this.$store.commit(linkTypes.SET_LINK_TAGS_SELECT_ALL, false)
        }
      }
    },
    async '$route.query.limit' (value) {
      this.$store.commit(linkTypes.SET_LINKS_LIMIT, value)
      // this.$store.dispatch('fetchLinks', 1)
      await this.fetchLinks(1)
      this.fetchLinkAnalytics()
    }
  }
})
</script>
