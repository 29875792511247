<template>
    <div class="modal_overlay">
        <transition name="fade">
            <div class="modal-custom modal-default-width">
                <slot name="close"></slot>
                <div class="container" style="width:100%;">
                    <div class=" col-md-10 modal-custom-flow txt-center">
                        <h4 class="txt-center no-user-select">
                            <slot name="modal_text">Modal Text</slot>
                        </h4>
                        <slot name="modal_form">
                            Modal Form
                        </slot>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>

                    <slot name="modal_button"></slot>
                </div>
                <div class="clearfix"></div>
            </div>
        </transition>
    </div>
</template>
<script>
export default ({
  'name': 'Modal'
})
</script>
